<template>
  <div
    ref="todosContainer"
  >
    <v-form
      v-if="assignedToProject($currentUser.id)"
      ref="searchForm"
      @submit.prevent="() => search('Enter')"
    >
      <v-text-field
        v-model="tmpQuery"
        outlined
        dense
        hide-details
        prepend-inner-icon="search"
        :placeholder="searchPlaceholder"
        class="pa-2 backgroundAccent"
        style="border-radius: 0;"
        clearable
        @click:clear="clearSearch"
      >
        <template
          slot="append-outer"
          class="ma-0"
        >
          <v-btn
            outlined
            rounded
            class="pa-0 ma-0"
            style="margin-top: -7px !important; height: 40px;"
            @click="() => search('Button')"
          >
            <span v-translate>
              Search
            </span>
          </v-btn>
        </template>
      </v-text-field>
    </v-form>

    <v-slide-y-reverse-transition
      hide-on-leave
    >
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto pb-4"
        type="tileHeader, todoCard, todoCardLast, tileHeader, todoCard"
        :types="{
          tileHeader: 'image',
          todoCard: 'image',
          todoCardLast: 'image',
        }"
      />

      <div
        v-else-if="!assignedToProject($currentUser.id)"
        class="d-flex flex-column justify-center align-center pa-12 caption"
      >
        <span
          v-translate
          translate-context="Page content (own todos)"
          class="d-block subtitle-1"
        >
          You have not been added to this project.
        </span>

        <m-tip
          class="mt-4"
          :tip="$pgettext(
            'MOwnTodosPage tip when user does not belong to any team',
            'Ask worksite management to add you to a team in this project.',
          )"
        />
      </div>

      <div
        v-else-if="!query && !hasAnyTodos"
        class="d-flex justify-center align-center pa-12 caption"
      >
        <span
          v-translate
          translate-context="Page content (own todos)"
          class="d-block subtitle-1"
        >
          Great job! All tasks done
        </span>

        <v-icon
          right
          color="secondary"
        >
          thumb_up
        </v-icon>
      </div>

      <div
        v-else-if="query && !hasAnyTodos"
        class="d-flex justify-center align-center flex-column py-12"
      >
        <span
          v-translate
          translate-context="Page content (own todos)"
          class="d-block subtitle-1"
        >
          No results.
        </span>

        <m-tip
          class="mt-4"
          :tip="$pgettext(
            'MOwnTodosPage tip when todos search result is empty',
            'Try to use space or task name.',
          )"
        />
      </div>

      <div
        v-else
      >
        <v-speed-dial
          v-model="fab"
          fixed
          transition="slide-y-reverse-transition"
          :style="{
            bottom: '68px',
            right: '12px',
            zIndex: 6,
          }"
          class="upcoming-workload"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              color="primary"
              dark
              fab
              large
              style="z-index: 6;"
            >
              <v-icon v-if="fab">
                close
              </v-icon>

              <span
                v-else
                class="d-flex justify-center align-center flex-column"
              >
                <span
                  v-translate
                  translate-context="Page content (own todos)"
                  class="caption-xxs mt-1"
                >
                  Coming up
                </span>

                <span
                  v-translate="{ upcomingWorkLoad: upcomingWorkLoad }"
                  translate-context="Page content (own todos)"
                  class="caption font-weight-bold"
                >
                  %{ upcomingWorkLoad } wd
                </span>
              </span>
            </v-btn>
          </template>

          <v-overlay
            :value="true"
          >
            <v-card
              width="90vw"
              elevation="12"
              class="mx-auto"
              light
            >
              <v-card-title
                v-translate
                translate-context="Page content (own todos) upcoming workload card"
              >
                Upcoming workload
              </v-card-title>

              <v-divider />

              <v-card-subtitle
                v-translate
                translate-context="Page content (own todos) upcoming workload card"
              >
                From this card you can make an estimate on how many workers
                you will need for the coming days.
              </v-card-subtitle>

              <v-card-text>
                <v-alert
                  dense
                  type="success"
                  icon="bubble_chart"
                  class="body-2"
                  border="left"
                >
                  <span
                    v-translate="{ upcomingWorkLoad: upcomingWorkLoad }"
                    translate-context="Page content (own todos) upcoming workload card"
                  >
                    Workload in the next takt is estimated
                    at <b>%{ upcomingWorkLoad } working days</b>*
                  </span>
                </v-alert>

                <m-team-workload-chart
                  :chart-data="chartData"
                  :y-axis-tick-callback="
                    v => `${v.toString().replace('.', ',')} ${upcomingWorkloadYTickAbbreviation()}`
                  "
                  :x-axis-options="{
                    ticks: {
                      maxRotation: 0,
                      minRotation: 0,
                      font: {
                        size: 8,
                      }
                    },
                  }"
                  :tooltip-callback="({ formattedValue: v }) =>
                    `${v.toString().replace('.', ',')} ${upcomingWorkloadYLabelFormatted(v)}`"
                  show-data-labels
                  chart-type="bar"
                  :subtitle="upcomingWorkloadChartSubtitle"
                  class="mt-4 ml-n2"
                  :width="1000"
                  :height="157"
                  scrollable
                />
              </v-card-text>
            </v-card>
          </v-overlay>
        </v-speed-dial>

        <m-todos-list
          v-if="hasDueTodos"
          :todos="todos.dueTodos"
          :title="todosListTitleOverdue"
          header-classes="error"
          content-classes="errorBackground"
        />

        <m-todos-list
          v-if="hasTodos"
          :todos="todos.todos"
          :title="todosListTitleCurrent"
          header-classes="info"
          content-classes="infoBackground"
          @sticked="onSticked"
        />

        <div
          v-if="showDueTodosChip"
          :style="{
            position: 'fixed',
            top: '80px',
            left: 0,
            right: 0,
            height: 'auto',
            zIndex: 10,
          }"
          class="d-flex justify-center"
        >
          <v-chip
            color="error lighten-2"
            dark
            @click="scrollToTop"
          >
            <v-icon
              left
              small
            >
              keyboard_arrow_up
            </v-icon>

            <span
              v-translate
              translate-context="Page content (own todos)"
            >
              Overdue tasks
            </span>

            <v-icon
              right
              small
            >
              keyboard_arrow_up
            </v-icon>
          </v-chip>
        </div>

        <m-todos-list
          v-if="hasFutureTodos && showFutureTodos"
          :todos="todos.futureTodos"
          :title="todosListTitleUpcoming"
          header-classes="backgroundAccent darken-4"
          content-classes="backgroundAccent lighten-4"
          @sticked="onSticked"
        />

        <div
          v-if="!showFutureTodos && (!query || (query && hasAnyTodos))"
          class="text-center pa-12"
        >
          <v-btn
            outlined
            x-large
            @click="showFutureTodos = !showFutureTodos"
          >
            <span
              v-translate
              translate-context="Page content (own todos)"
            >
              Show upcoming takts
            </span>
          </v-btn>
        </div>
      </div>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MTodosList from '@/components/MTodosList';
  import MTeamWorkloadChart from '@/components/charts/MTeamWorkloadChart';

  export default {
    components: {
      MTeamWorkloadChart,
      MTodosList,
    },

    data: () => ({
      loading: false,
      showFutureTodos: false,
      tmpQuery: null,
      query: null,
      showDueTodosChip: false,
      fab: false,
      chartData: {},
    }),

    computed: {
      ...mapGetters({
        todosByTakt: 'project/todos/todosByTakt',
        currentTact: 'project/tacts/currentTact',
        tacts: 'project/tacts/tacts',
        assignedToProject: 'project/teamsUsers/assignedToProject',
        storeTodos: 'project/todos/todos',
        datesByTaktOrder: 'project/tacts/datesByTaktOrder',
      }),

      upcomingWorkLoad() {
        return this.chartData.dataset[0].toString().replace('.', ',');
      },

      hasDueTodos() {
        return Object.values(this.todos.dueTodos).flat().length > 0;
      },

      hasTodos() {
        return Object.values(this.todos.todos).flat().length > 0;
      },

      hasFutureTodos() {
        return Object.values(this.todos.futureTodos).flat().length > 0;
      },

      hasAnyTodos() {
        return this.hasDueTodos || this.hasTodos || this.hasFutureTodos;
      },

      /**
       * 1) Puts into three categories: due, now, future.
       * 2) Handles query string (for search)
       * 3) Combines data into [takt]: [todos] -pairs
       *
       * @returns {{dueTodos: {}, futureTodos: {}, todos: {}}|*}
       */
      todos() {
        const def = {
          dueTodos: {},
          todos: {},
          futureTodos: {},
        };

        const {
          taktIndex: currentTaktOrder,
        } = this.currentTact;

        const r = Object.keys(this.todosByTakt).reduce((acc, __taktOrder) => {
          const taktOrder = Number.parseInt(__taktOrder, 10);
          const rawData = this.todosByTakt[taktOrder];

          let data;

          if (this.query) {
            const q = this.query.toString().toLocaleLowerCase().trim();
            const regExp = new RegExp(q);

            data = rawData.filter((todo) => {
              const taskName = todo.taskName.toString().toLocaleLowerCase().trim();
              const address = todo.address.toString().toLocaleLowerCase().trim();

              return regExp.test(taskName) || regExp.test(address);
            });
          } else {
            data = rawData;
          }

          if (taktOrder < currentTaktOrder) {
            return {
              ...acc,
              dueTodos: {
                ...acc.dueTodos,
                [taktOrder]: data,
              },
            };
          }

          if (taktOrder === currentTaktOrder) {
            return {
              ...acc,
              todos: {
                ...acc.todos,
                [taktOrder]: data,
              },
            };
          }

          if (taktOrder > currentTaktOrder) {
            return {
              ...acc,
              futureTodos: {
                ...acc.futureTodos,
                [taktOrder]: data,
              },
            };
          }

          return acc;
        }, def);

        return r;
      },

      searchPlaceholder() {
        return this.$pgettext('Search placeholder', 'Search for space or task...');
      },

      upcomingWorkloadChartSubtitle() {
        return this.$pgettext('Upcoming workload chart subtitle', '* 1 working day (wd) equates 8 working hours');
      },

      todosListTitleOverdue() {
        return this.$pgettext('Todos list title', 'Overdue');
      },

      todosListTitleCurrent() {
        return this.$pgettext('Todos list title', 'Current');
      },

      todosListTitleUpcoming() {
        return this.$pgettext('Todos list title', 'Upcoming');
      },
    },

    watch: {
      fab(opened) {
        if (opened) {
          this.$mixpanel.trackEvent('Open Own Todos Workload Chart');
        }
      },
    },

    async created() {
      this.loading = true;

      /**
       * This needs to be loaded first since the
       * following loadTodos() depends on it.
       */
      await this.loadCurrentTact();

      await Promise.all([
        this.loadTodos({
          userIds: [this.$currentUser.id],
          whereNotState: ['completed', 'skipped'],

          /**
           * Hard-coded +10 for now, will implement
           * pagination + infinite scroll later on.
           */
          taktTo: this.currentTact.taktIndex + 10,
        }),
        this.loadTacts(),
        this.loadTeamsUsers(),
        this.loadUsers({ projectId: this.$projectId, groupBy: 'id' }),
      ]);

      await this.prepareChartData();

      setTimeout(() => {
        this.loading = false;
      });
    },

    methods: {
      ...mapActions({
        loadTeamsUsers: 'project/teamsUsers/loadTeamsUsers',
        loadUsers: 'users/loadUsers',
      }),

      ...mapWaitingActions('project/todos', {
        loadTodos: 'loading initial data',
      }),

      ...mapWaitingActions('project/tacts', {
        loadTacts: 'loading initial data',
        loadCurrentTact: 'loading initial data',
      }),

      async prepareChartData() {
        const fromTact = this.currentTact.taktIndex;
        const toTact = fromTact + 10; // Next 10 takts
        const takts = {};

        for (let taktOrder = fromTact; taktOrder <= toTact; taktOrder += 1) {
          takts[taktOrder] = 0;
        }

        const workloads = this.storeTodos.reduce((acc, todo) => {
          if (todo.takt < fromTact) return acc;

          const duration = todo.estimatedDuration / 60 / 8; // Minutes -> hours -> workdays
          const durationSum = acc[todo.takt] + duration;
          const finalSum = Math.round(durationSum * 100) / 100; // Two decimals

          return {
            ...acc,
            [todo.takt]: finalSum,
          };
        }, takts);

        const dataset = Object.values(workloads);
        const labels = this
          .tacts
          .filter(tact => tact.taktIndex >= fromTact && tact.taktIndex <= toTact)
          .map(({ taktIndex }) => {
            const datesByOrder = this.datesByTaktOrder[taktIndex];
            const {
              dayAndTimeFormatted,
              datesFormatted,
            } = datesByOrder;

            return `${dayAndTimeFormatted} (${datesFormatted})`;
          });

        this.chartData = {
          dataset,
          labels,
        };
      },

      onSticked({ sticked }) {
        this.showDueTodosChip = sticked && this.hasDueTodos;
      },

      scrollToTop() {
        this.$vuetify.goTo(this.$refs.todosContainer);
        this.$mixpanel.trackEvent('Scroll Due Todos To Top');
      },

      search(submitter) {
        this.query = this.tmpQuery;
        document.activeElement.blur();

        this.$gtm.trackEvent({
          category: 'Todo Search',
          action: 'Form Submit',
          label: submitter,
          value: this.query,
        });
      },

      clearSearch() {
        this.query = null;
        this.tmpQuery = null;
      },

      upcomingWorkloadYTickAbbreviation() {
        return this.$pgettext('Working day abbreviation', 'wd');
      },

      upcomingWorkloadYLabelFormatted(yLabel) {
        return this.$ngettext('working day', 'working days', yLabel);
      },
    },
  };
</script>

<style lang="scss">
  .v-skeleton-loader__tileHeader {
    height: 55px;
    margin-bottom: 16px;
    border-radius: 0;
  }

  .v-skeleton-loader__todoCard {
    height: 96px;
    margin: 0 24px 8px;
  }

  .v-skeleton-loader__todoCardLast {
    height: 96px;
    margin: 0 24px 58px;
  }
</style>
