<template>
  <div>
    <div
      :class="{
        'overflow-x-scroll': scrollable,
      }"
      :style="{
        boxShadow: scrollable ? 'inset -6px 0 10px -10px rgba(0, 0, 0, 0.75)' : '',
      }"
    >
      <div
        :style="`width: ${width}px;`"
      >
        <canvas
          ref="workLoadChart"
          :width="width"
          :height="height"
        />
      </div>
    </div>

    <div
      v-if="chart"
      class="caption font-italic mt-4 text-center"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
  import Chart from 'chart.js/auto';
  import ChartDataLabels from 'chartjs-plugin-datalabels';

  export default {
    props: {
      chartData: {
        type: Object,
        required: true,
      },

      chartType: {
        type: String,
        default: 'line',
      },

      subtitle: {
        type: String,
        default: '',
      },

      yAxisTickCallback: {
        type: Function,
        default: v => v,
      },

      tooltipCallback: {
        type: Function,
        default: ({ formattedValue }) => formattedValue,
      },

      showDataLabels: {
        type: Boolean,
        default: false,
      },

      xAxisOptions: {
        type: Object,
        default: () => ({}),
      },

      width: {
        type: [Number, String],
        default: () => 'auto',
      },

      height: {
        type: [Number, String],
        default: () => 'auto',
      },

      scrollable: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      chart: null,
    }),

    watch: {
      chartData() {
        this.initChart();
      },
    },

    mounted() {
      this.initChart();
    },

    methods: {
      async initChart() {
        if (this.chart) {
          this.chart.destroy();
          this.chart = null;
        }

        const {
          labels = [],
          dataset = [],
        } = this.chartData;

        if (labels.length <= 0 || dataset.length <= 0) return;

        this.chart = new Chart(this.$refs.workLoadChart, { // eslint-disable-line
          type: this.chartType,
          plugins: this.showDataLabels ? [
            ChartDataLabels,
          ] : [],
          data: {
            labels,
            datasets: [
              {
                data: dataset,
                backgroundColor: 'rgba(255, 87, 34, 0.25)',
                borderColor: 'rgba(255, 87, 34, 0.5)',
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: this.tooltipCallback,
                },
              },
              datalabels: {
                formatter: value => value.toString().replace('.', ','),
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: this.yAxisTickCallback,
                  beginAtZero: true,
                  stepSize: 1,
                },
              },

              x: this.xAxisOptions,
            },
          },
        });
      },
    },
  };
</script>
