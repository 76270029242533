<template>
  <div
    :class="contentClasses"
  >
    <template
      v-for="(_, tactOrder) in todos"
    >
      <div
        v-if="todos[tactOrder].length > 0"
        :key="tactOrder"
      >
        <div
          sticky-container
          class="pb-6"
        >
          <!-- some weird bug in the lib, hence the weird sticky-offset implementation -->
          <div
            v-sticky
            :sticky-offset="`{ top: ${ $vuetify.breakpoint.mobile ? 0 : 48 } }`"
            sticky-z-index="1"
            on-stick="onStick"
          >
            <v-card
              tile
              :class="`pa-2 elevation-6 tact-order-${tactOrder} ${headerClasses}`"
            >
              <div
                class="d-flex justify-space-between align-center"
              >
                <div
                  :style="{
                    width: '60px',
                  }"
                >
                  <div
                    class="font-weight-bold white--text"
                  >
                    T{{ Number.parseInt(tactOrder, 10) }}
                  </div>
                </div>

                <div
                  class="white--text text-uppercase caption text-center"
                >
                  <div
                    class="caption-xs"
                  >
                    {{ title }}
                  </div>

                  <div
                    class="font-weight-bold my-n1"
                  >
                    {{ dates(tactOrder).dayAndTimeFormatted }}
                  </div>

                  <div
                    class="caption-xs"
                  >
                    ({{ dates(tactOrder).datesFormatted }})
                  </div>
                </div>

                <div
                  class="text-right"
                  :style="{
                    width: '60px',
                  }"
                >
                  <!-- eslint-disable -->
                  <div
                    class="caption-xs font-weight-bold backgroundAccent--text text--lighten-5"
                    v-html="totalEstimatedDuration(tactOrder)"
                  />
                  <!-- eslint-enable -->
                </div>
              </div>
            </v-card>
          </div>

          <v-card-text>
            <m-todos-feed-card
              v-for="(todo, i) in todos[tactOrder]"
              ref="todoRef"
              :key="`order-${tactOrder}-i-${i}`"
              :todo="todo"
              class="mb-2 mx-auto"
            />
          </v-card-text>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import MTodosFeedCard from '@/components/MTodosFeedCard';

  export default {
    components: {
      MTodosFeedCard,
    },

    props: {
      todos: {
        type: Object,
        required: true,
      },

      title: {
        type: String,
        default: '',
      },

      headerClasses: {
        type: String,
        default: '',
      },

      contentClasses: {
        type: String,
        default: '',
      },
    },

    computed: {
      ...mapGetters({
        datesByTaktOrder: 'project/tacts/datesByTaktOrder',
      }),
    },

    methods: {
      totalEstimatedDuration(tactOrder) { // eslint-disable-line
        const todos = this.todos[tactOrder];
        const minutesTotal = todos.reduce((acc, todo) => {
          if (todo.assignmentState === 'completed') return acc;
          return acc + todo.estimatedDuration;
        }, 0);
        const hours = (minutesTotal / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        const hoursRendered = this.$gettextInterpolate(
          this.$ngettext('%{ rhours } hour', '%{ rhours } hours', rhours),
          { rhours },
        );

        const minutesRendered = this.$gettextInterpolate(
          this.$ngettext('%{ rminutes } minute', '%{ rminutes } minutes', rminutes),
          { rminutes },
        );

        return `${hoursRendered}<br>${minutesRendered}`;
      },

      dates(order) {
        const datesByOrder = this.datesByTaktOrder[order];

        const {
          dayAndTimeFormatted,
          datesFormatted,
        } = datesByOrder;

        return {
          dayAndTimeFormatted,
          datesFormatted,
        };
      },

      onStick(payload) {
        this.$emit('sticked', payload);
      },
    },
  };
</script>
